import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsServiceTask = [
  {
    text: "ID",
    value: "id",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "Приоритет",
    value: "priority",
    renderFunction: value =>
      `<span style="color: ${value.priority === 1 ? "red" : ""}">${
        dictionariesHelper.serviceTaskPriority[value.priority]
      }</span>`,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Срок выполнения",
    value: "deadline_at",
    renderFunction: value =>
      `${
        moment(value.deadline_at * 1000).isValid()
          ? moment(value.deadline_at * 1000).format("DD.MM.YYYY")
          : "-"
      } `,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Статус",
    value: "status",
    renderFunction: value =>
      `<span style="padding: 0 15px;background-color: ${
        value.status === "in-process"
          ? "#95C23D"
          : value.status === "finished"
          ? "#FFA943"
          : value.status === "closed"
          ? "#D0D1D9"
          : value.status === "repeated"
          ? "#6D778E"
          : value.status === "dismissed"
          ? "#EB5C6D"
          : "#515151"
      }; color: #ffffff; border-radius: 10px">${
        dictionariesHelper.serviceTask[value.status]
      }</span> `,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Тип заявки",
    value: "task_type",
    renderFunction: value =>
      `${dictionariesHelper.serviceTask[value.task_type]}`,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Тема",
    value: "title",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Адрес",
    // value: "address",
    renderFunction: value => value?.object_details?.address,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Объект",
    // value: "object",
    renderFunction: value => value?.object_details?.name,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    text: "Контроллер",
    value: "controller",
    renderFunction: value => value?.controller_details?.name,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_5.svg",
    width: 20,
    sort: true
  },
  {
    text: "Инициатор",
    value: "init_type",
    renderFunction: value =>
      `${dictionariesHelper.serviceTask[value.init_type]}`,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Техник",
    value: "service_specialist",
    renderFunction: value => value?.specialist_details?.full_name,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Телефон",
    // value: "service_specialist_phone",
    renderFunction: value =>
      phoneFilter.execute(value?.specialist_details?.phone_number),

    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Дата создания",
    renderFunction: value =>
      `${
        moment(value.created_at * 1000).isValid()
          ? moment(value.created_at * 1000).format("DD.MM.YYYY")
          : "-"
      }`,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Дата изменения",
    renderFunction: value =>
      `${
        moment(value.updated_at * 1000).isValid()
          ? moment(value.updated_at * 1000).format("DD.MM.YYYY")
          : "-"
      }`,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  }
];

export default tableColsServiceTask;
